<template lang='pug'>
	.wizard-wrap-xl.container-xs-24.mx-sm-auto.mb-5.lh-18.privacy
		h1.mb-6.text-center.d-block(v-html="$t('privacyAccept.title2')")

		p.mb-4(v-html="$t('privacyAccept.desc')")

		ul.list-decimal
			li(v-for='i in list' :key='i.text')
				span(v-html='i.text')

				table.my-4.table.b-table.table-bordered(v-if='i.table')
					thead
						tr
							th.f-1.font-weight-normal {{ i.table.th[0] }}
							th.f-1.font-weight-normal {{ i.table.th[1] }}
					tbody
						tr(v-for='tr in i.table.tr')
							td(v-for='td in tr')
								slot(v-for='tdi in td')
									p.mb-1(v-for='tdii in tdi') {{ tdii }}
				ul(v-if='i.list')
					li(v-for='l in i.list') {{ l }}

				company-data.mt-3(v-if='i.companyData')
		br
		br
		pw-btn-up
</template>

<script>
import { mapGetters } from 'vuex'
import CompanyData from '@/components/Common/Company'
import PwBtnUp from '@/components/Buttons/PwBtnUp'
import {bus} from '@/main'

export default {
	metaInfo () { return {title: bus.$t('privacyAccept.title')}},
	components: {
		CompanyData,
		PwBtnUp
	},
	data() {
		return {

		}
	},
	computed: {
		list () {
			let items = _.cloneDeep(bus.$t('privacyAccept.list'));

			_.each(items, (m, i) => {
				items[i].text = items[i].text.replace(/\$\{bus\.botPayName\}/, bus.botPayName);
				items[i].text = items[i].text.replace(/\$\{bus\.botSupportName\}/, bus.botSupportName);
				items[i].text = items[i].text.replace(/\$\{bus\.botSupport\}/, bus.botSupport);
			})

			return items;
		}
	},
	created () {
		window.scrollTo(0,0);
	}
}
</script>

<style lang="scss" scoped>
.privacy {
	margin-top: 76px;

	@media(max-width: 767px) {
		margin-top: 20px;
	}
}

.list-decimal {
	li {
		margin-bottom: 10px;
	}
}
</style>
